




function partnerStackSignUp(user) {
  var name = user['firstName'];
  var email = user['email'];
  growsumo.data.name = name;
  growsumo.data.email = email;
  growsumo.data.customer_key = email;
  growsumo.createSignup();
}