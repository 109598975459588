




function salesTrack(obj) {
    var papTotal = obj['amount']; // total cost variable here
    var papOrderID = obj['orderId']; // order ID here
    var papProductID = obj['productId']; // (optional) product ID/SKU/NAME
    var papCustomer = obj['customer']; // customer identification, e.g. ID or email

    // for more products, loop this:
    var sale = PostAffTracker.createSale();
    sale.setTotalCost(papTotal);
    sale.setOrderID(papOrderID);
    sale.setProductID(papProductID);
    sale.setData1(papCustomer);
    PostAffTracker.register();
}


